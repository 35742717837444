const CompareDates = (selectorDate, itemDate) => {
  const d1 = new Date(
    selectorDate.getFullYear(),
    selectorDate.getMonth(),
    selectorDate.getDate()
  );
  const d2 = new Date(
    itemDate.getFullYear(),
    itemDate.getMonth(),
    itemDate.getDate()
  );
  if (d1 == d2) {
    return true;
  } else {
    return false;
  }
};
const GetWeekNumber = (date) => {
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const lunes = new Date(date);
  const domingo = new Date(date);
  domingo.setDate(date.getDate() - date.getDay() + 1 + 6);
  lunes.setDate(date.getDate() - date.getDay() + 1);
  const yearStart = new Date(date.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  // console.log("Fecha desde:" + lunes);
  // console.log("Fecha domin:" + domingo);
  return { lunes: lunes, week: weekNumber, domingo: domingo };
};
export { CompareDates, GetWeekNumber };
