import React, { useRef, useState, useEffect } from "react";
import css from "../DateSelector/DateSelector.module.css";

const DateSelector = ({ onChange }) => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    if (!onChange) return;
    onChange(NormalizeDate(date));
  }, []);
  function Add() {
    setDate(new Date(date.setDate(date.getDate() + 1)));
    Changed(date);
  }
  function Remove() {
    setDate(new Date(date.setDate(date.getDate() - 1)));
    Changed(date);
  }
  function Changed(date) {
    if (!onChange) return;
    onChange(NormalizeDate(date));
  }
  function NormalizeDate(date) {
    const fixed = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return fixed;
  }
  return (
    <div className={css.container}>
      <p className={css.title}>
        {date.toLocaleDateString("es-CL", {
          weekday: "long",
          month: "long",
          day: "numeric",
        })}
      </p>
      <div className={css.row}>
        <p
          className={css.btn}
          onClick={() => {
            Remove();
          }}
        >
          ◀
        </p>
        <input
          type="date"
          className={css.input}
          value={date.toLocaleDateString("en-CA")}
          onChange={(e) => {
            setDate(
              new Date(
                e.target.valueAsDate.setDate(e.target.valueAsDate.getDate() + 1)
              )
            );
            Changed(
              new Date(
                e.target.valueAsDate.setDate(e.target.valueAsDate.getDate() + 1)
              )
            );
          }}
        />
        <p
          className={css.btn}
          onClick={() => {
            Add();
          }}
        >
          ▶
        </p>
      </div>
    </div>
  );
};

export default DateSelector;
