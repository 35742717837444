// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD5xb-DY5uodjet5WniWTFMPVDYHrZmd4s",
  authDomain: "saapp-b154e.firebaseapp.com",
  databaseURL: "https://saapp-b154e-default-rtdb.firebaseio.com",
  projectId: "saapp-b154e",
  storageBucket: "saapp-b154e.appspot.com",
  messagingSenderId: "34805488907",
  appId: "1:34805488907:web:9d6ad62d3226be4de5160e",
  measurementId: "G-FC8PHFTZ02",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage();
