import React, { useState } from "react";
import css from "../PrediRange/PrediRange.module.css";
import DateSelector from "../../DateSelector/DateSelector";
import PredicacionMain from "../PredicacionMain/PredicacionMain";
import SingleBtn from "../../SingleBtn/SingleBtn";

const PrediRange = () => {
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [update, setUpdate] = useState(false);
  return (
    <div className={css.main}>
      <div className={css.dates}>
        <DateSelector
          onChange={(date) => {
            setFrom(date);
          }}
        />
        <DateSelector
          onChange={(date) => {
            setTo(date);
          }}
        />
        <SingleBtn
          isSave={true}
          text={"Actualizar"}
          onClick={() => {
            setUpdate(!update);
          }}
        />
      </div>
      <PredicacionMain
        from={from.getTime()}
        to={to.getTime()}
        update={update}
      />
    </div>
  );
};

export default PrediRange;
