import React, { useState, useEffect } from "react";
import css from "../Admin/Admin.module.css";
import PrediEditor from "./PrediEditor/PrediEditor";
import VMCEditor from "../VMCEditor/VMCEditor";
import SingleBtn from "../SingleBtn/SingleBtn";
import { auth } from "../../Services/Firebase/Fire";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { Link } from "react-router-dom";
import Datos from "../Datos/Datos";
import Spinner from "../Spinner/Spinner";

const Admin = () => {
  const [logged, setLogged] = useState(false);
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [isLogging, setIsLogging] = useState(false);

  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogged(true);
      } else {
        setLogged(false);
      }
    });
    return () => {
      unlisten();
    };
  }, []);
  function Log() {
    if (mail == "" || pass == "") return;
    setIsLogging(true);
    signInWithEmailAndPassword(auth, mail, pass)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setMail("");
        setPass("");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      })
      .finally(() => {
        setIsLogging(false);
      });
  }

  if (logged) {
    return (
      <div className={css.main}>
        <h2 className={css.text}>Editar</h2>
        <div className={css.row}>
          <Link to={"/meeting"} className={css.link}>
            VMC
          </Link>
          <Link to={"/ss"} className={css.link}>
            Predicación
          </Link>
        </div>
        <div className={css.col}>
          <SingleBtn
            text="Cerrar sesión"
            onClick={() => {
              signOut(auth);
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={css.log}>
        <h2 className={css.text}>Login</h2>
        <input
          type="text"
          className={css.input}
          placeholder="Correo"
          onChange={(e) => {
            setMail(e.target.value);
          }}
        />
        <input
          type="password"
          className={css.input}
          placeholder="Contraseña"
          onChange={(e) => {
            setPass(e.target.value);
          }}
        />
        <SingleBtn
          text={"Iniciar sesión"}
          isVisible={mail != "" && pass != ""}
          isSave={true}
          onClick={() => {
            Log();
          }}
        />
        <Spinner pageSize={false} isVisible={isLogging} />
      </div>
    );
  }
};

export default Admin;
