import React, { useEffect, useState } from "react";
import css from "../Seccion/Seccion.module.css";

const Seccion = ({ nombre = "tesoros" }) => {
  let tesoros = "Tesoros de la Biblia";
  let maestros = "Seamos mejores maestros";
  let vida = "Nuestra vida cristiana";
  const [text, setText] = useState("");

  useEffect(() => {
    switch (nombre) {
      case "tesoros":
        setText(tesoros);
        break;
      case "maestros":
        setText(maestros);
        break;
      case "vida":
        setText(vida);
        break;

      default:
        setText("Sin texto");
        break;
    }
  }, []);

  return (
    <div className={css.main}>
      <img
        src={`../imgs/${nombre}.png`}
        style={{
          backgroundColor:
            text === tesoros
              ? "var(--tesoros-color)"
              : text === maestros
              ? "var(--maestros-color)"
              : "var(--vida-color)",
        }}
        className={css.img}
      />
      <p className={css.text}>{text}</p>
    </div>
  );
};

export default Seccion;
