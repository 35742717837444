import React from "react";
import css from "../PrediEditor/PrediEditor.module.css";
import Selector from "../Selector/Selector";
import { useEffect, useState } from "react";
import {
  AddCarritoItem,
  AddPrediItem,
  DeleteCarritoItem,
  DeletePrediItem,
  EditCarritoItem,
  EditPrediItem,
  GetCarritoData,
  GetDatosByCity,
  GetDatosByCityFiltered,
  GetFormData,
  GetPrediData,
} from "../../../Services/Firestore/Helper";
import Btn from "../../Btn/Btn";
import DateSelector from "../../DateSelector/DateSelector";
import PrediList from "../PrediList/PrediList";
import { Timestamp } from "firebase/firestore";
import SingleBtn from "../../SingleBtn/SingleBtn";
import InputText from "../../InputText/InputText";
import CarritoList from "../CarritoList/CarritoList";
import FileUploader from "../../FileUploader/FileUploader";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../../Services/Firebase/Fire";
import Spinner from "../../Spinner/Spinner";

const PrediEditor = () => {
  const [content, setContent] = useState([]);
  const [prediData, setPrediData] = useState([]);
  const [listData, setListData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentItem, setCurrentItem] = useState({ especial: false });
  const [isEdit, setIsEdit] = useState(false);
  const [displayWindow, setDisplayWindow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selection, setSelection] = useState("Predicación");
  const [sordos, setSordos] = useState([]);
  useEffect(() => {
    Reload();
  }, []);
  useEffect(() => {
    Reload();
  }, [selection]);

  useEffect(() => {
    LoadList();
  }, [date, content, prediData]);
  useEffect(() => {
    // console.log(currentItem);
  }, [currentItem]);
  function LoadList() {
    if (prediData.length >= 0) {
      setListData(
        prediData.filter(
          (e) =>
            new Date(e.date).setHours(0, 0, 0, 0) ===
            new Date(date).setHours(0, 0, 0, 0)
        )
      );
    } else {
      setListData([]);
    }
  }
  const Reload = () => {
    GetFormData(setIsLoaded, setContent);
    if (selection == "Predicación") {
      GetPrediData(setIsLoaded, setPrediData);
      GetDatosByCityFiltered(setIsLoaded, setSordos, "Angol");
    } else {
      GetCarritoData(setIsLoaded, setPrediData);
    }
  };
  function OnEdit(item) {
    setCurrentItem(item);
    setDisplayWindow(true);
    setIsEdit(true);
  }
  function OnDeletePredi(item) {
    DeletePrediItem(item, Reload);
  }
  function OnDeleteCarrito(item) {
    DeleteCarritoItem(item, Reload);
  }

  function RenderList() {
    if (selection == "Predicación") {
      return (
        <PrediList
          data={listData}
          onEdit={(e) => OnEdit(e)}
          onDelete={(e) => {
            OnDeletePredi(e);
          }}
        />
      );
    } else {
      return (
        <CarritoList
          data={listData}
          onEdit={(e) => OnEdit(e)}
          onDelete={(e) => {
            OnDeleteCarrito(e);
          }}
        />
      );
    }
  }
  function RenderSpecial() {
    if (currentItem.especial) {
      return (
        <div className={css.box}>
          <InputText
            placeholder={"Link"}
            isVisible={displayWindow}
            title="Link"
            initialText={currentItem?.link}
            OnChangeText={(text) => {
              setCurrentItem({ ...currentItem, link: text });
            }}
          />
          <InputText
            placeholder={"Texto del botón"}
            isVisible={displayWindow}
            title="Texto del botón"
            initialText={currentItem?.linkText}
            OnChangeText={(text) => {
              setCurrentItem({ ...currentItem, linkText: text });
            }}
          />
          <img
            src={currentItem?.img}
            className={css.imgPreview}
            style={{ display: currentItem?.img ? "" : "none" }}
            onClick={() => {
              openInNewTab(currentItem?.img);
            }}
          />
          <div className={css.row}>
            <img
              src="/imgs/borrar.png"
              className={css.delete}
              style={{ display: currentItem?.img ? "" : "none" }}
              onClick={() => {
                ResetImg();
              }}
            />
            <FileUploader
              archivo={`${currentItem?.date}.webp`}
              ruta={`predicacion`}
              fileType={"image/png, image/jpeg"}
              resolutionX={3000}
              resolutionY={3000}
              currentImg={currentItem?.img}
              returnURL={(url) => {
                setCurrentItem({ ...currentItem, img: url });
              }}
            />
          </div>
        </div>
      );
    }
  }

  function OnAdd() {
    setCurrentItem({
      date: date.getTime(),
    });
    setDisplayWindow(true);
    setIsEdit(false);
  }

  function MakeDateTimeHour(horario) {
    // console.log(horario);
    const result = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      Number(horario.slice(0, 2).trim()),
      Number(horario.slice(3, 5).trim()),
      0,
      0
    ).getTime();
    // console.log(result);
    setCurrentItem({
      ...currentItem,
      date: result,
      horario: horario,
    });
  }
  function OnSaveBtn() {
    if (selection == "") return;
    if (selection == "Predicación") {
      if (isEdit) {
        EditPrediItem(currentItem, Reload);
      } else {
        AddPrediItem(currentItem, Reload);
      }
    } else {
      if (isEdit) {
        EditCarritoItem(currentItem, Reload);
      } else {
        AddCarritoItem(currentItem, Reload);
      }
    }
    setDisplayWindow(false);
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  function ResetImg() {
    if (currentItem?.img != "") {
      deleteObject(
        ref(
          storage,
          `predicacion/${currentItem?.date + currentItem?.detalle}.webp`
        )
      )
        .then(() => {})
        .catch((error) => {})
        .finally(() => {
          setCurrentItem({ ...currentItem, img: "" });
        });
    }
  }
  if (!isLoaded) {
    return <Spinner />;
  } else {
    return (
      <div className={css.main}>
        <select
          className={css.title}
          onChange={(e) => {
            setSelection(e.target.value);
          }}
        >
          <option>Predicación</option>
          <option>Carrito</option>
        </select>
        <DateSelector
          onChange={(e) => {
            setDate(e);
          }}
        />
        <SingleBtn
          text="Añadir"
          isSave={true}
          onClick={() => {
            OnAdd();
          }}
        />
        <div className={css.list}>{RenderList()}</div>
        {/* Ventana de edición */}
        <div
          className={css.bg}
          style={{
            display: displayWindow && selection == "Predicación" ? "" : "none",
          }}
        >
          <div className={css.window}>
            <div className={css.content}>
              <p className={css.title}>
                {isEdit ? "Editar Predicación" : "Añadir Predicación"}
              </p>
              <label className={css.check}>
                <input
                  type="checkbox"
                  checked={currentItem?.especial || false}
                  onChange={(e) => {
                    setCurrentItem({
                      ...currentItem,
                      especial: e.target.checked,
                    });
                  }}
                />
                Especial
              </label>
              <Selector
                optionsList={content.horarios.predicacion}
                title={"Horario"}
                selectedValue={currentItem.horario}
                isVisible={displayWindow}
                returnValue={(result) => {
                  MakeDateTimeHour(result);
                }}
              />
              <Selector
                optionsObj={[...content.lugares, ...content.personas]}
                title={"Lugar"}
                selectedValue={currentItem.lugar}
                isVisible={displayWindow}
                returnValue={(result) => {
                  // console.log(result);
                  setCurrentItem({
                    ...currentItem,
                    lugar: result,
                    imgLugar:
                      [...content.lugares, ...content.personas]
                        .find((i) => i.name === result)
                        ?.img.trim() || "",
                  });
                }}
              />
              <Selector
                optionsObj={content.tipos}
                title={"Tipo de predicación"}
                isVisible={displayWindow}
                selectedValue={currentItem.tipo}
                returnValue={(result) => {
                  setCurrentItem({
                    ...currentItem,
                    tipo: result,
                    imgTipo:
                      content.tipos
                        .find((i) => i.name === result)
                        ?.img.trim() || "",
                  });
                }}
              />

              <Selector
                optionsObj={content.personas}
                selectedValue={currentItem?.encargado}
                title={"Encargado"}
                isVisible={displayWindow}
                returnValue={(result) => {
                  setCurrentItem({
                    ...currentItem,
                    encargado: result,
                    imgEnc:
                      content.personas
                        .find((i) => i.name === result)
                        ?.img.trim() || "",
                  });
                }}
              />
              <InputText
                placeholder={"Detalle"}
                isVisible={displayWindow}
                title="Detalle"
                initialText={currentItem?.detalle}
                OnChangeText={(text) => {
                  setCurrentItem({ ...currentItem, detalle: text });
                }}
              />
              {RenderSpecial()}
              <div className={css.row}>
                {currentItem?.territorio?.map((item) => {
                  return (
                    <div
                      className={css.dato}
                      onClick={() => {
                        let tmp = currentItem?.territorio?.filter(
                          (ter) => ter != item
                        );
                        setCurrentItem({
                          ...currentItem,
                          territorio: tmp,
                        });
                      }}
                    >
                      <p className={css.text}>{item}</p>
                    </div>
                  );
                })}
              </div>
              <div
                className={css.box}
                style={{ display: currentItem?.tipo == "sordos" ? "" : "none" }}
              >
                <Selector
                  optionsObj={sordos}
                  title={"Agregar dato"}
                  isVisible={displayWindow}
                  selectedValue={currentItem?.territorio}
                  returnValue={(result) => {
                    setCurrentItem({
                      ...currentItem,
                      territorio: [
                        ...(currentItem?.territorio?.length > 0
                          ? [...currentItem?.territorio, result]
                          : [result]),
                      ],
                    });
                  }}
                />
              </div>
              <Btn
                onDiscard={() => {
                  setDisplayWindow(false);
                }}
                onSave={() => {
                  OnSaveBtn();
                }}
              />
            </div>
          </div>
        </div>

        {/* EDICION CARRITO */}
        <div
          className={css.bg}
          style={{
            display: displayWindow && selection == "Carrito" ? "" : "none",
          }}
        >
          <div className={css.window}>
            <div className={css.content}>
              <p className={css.title}>
                {isEdit ? "Editar Carrito" : "Añadir Carrito"}
              </p>
              <Selector
                optionsList={content.horarios.carrito}
                title={"Horario"}
                selectedValue={currentItem.horario}
                isVisible={displayWindow}
                returnValue={(result) => {
                  MakeDateTimeHour(result);
                }}
              />
              <Selector
                optionsObj={[...content.lugares, ...content.personas]}
                title={"Lugar"}
                selectedValue={currentItem.lugar}
                isVisible={displayWindow}
                returnValue={(result) => {
                  setCurrentItem({
                    ...currentItem,
                    lugar: result,
                    imgLugar:
                      [...content.lugares, ...content.personas]
                        .find((i) => i.name === result)
                        ?.img.trim() || "",
                  });
                }}
              />
              <Selector
                optionsObj={content.personas}
                selectedValue={currentItem.persona1}
                title={"Encargado"}
                isVisible={displayWindow}
                returnValue={(result) => {
                  setCurrentItem({
                    ...currentItem,
                    persona1: result,
                    imgPersona1:
                      content.personas
                        .find((i) => i.name === result)
                        ?.img.trim() || "",
                  });
                }}
              />
              <Selector
                optionsObj={content.personas}
                selectedValue={currentItem.persona2}
                title={"Acompañante"}
                isVisible={displayWindow}
                returnValue={(result) => {
                  setCurrentItem({
                    ...currentItem,
                    persona2: result,
                    imgPersona2:
                      content.personas
                        .find((i) => i.name === result)
                        ?.img.trim() || "",
                  });
                }}
              />

              <Btn
                onDiscard={() => {
                  setDisplayWindow(false);
                }}
                onSave={() => {
                  OnSaveBtn();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PrediEditor;
