import React, { useState } from "react";
import css from "../Territorios/Territorios.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

const Territorios = () => {
  const [data, setData] = useState({ name: "", link: "" });

  const nav = useNavigate();
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      nav("/");
    },
    onSwipedRight: (eventData) => {
      nav("/vmc");
    },
    delta:70,
  });
  return (
    <div className={css.main} {...handlers}>
      <p className={css.title}>Mapas</p>
      <div className={css.linkContainer}>
        <p
          className={css.link}
          onClick={() => {
            setData({
              name: "Confines",
              img: "https://firebasestorage.googleapis.com/v0/b/saapp-b154e.appspot.com/o/mapas%2Fangol%2FConfines.png?alt=media&token=1d40619e-0cba-45d6-b321-8938a683cf64",
            });
          }}
        >
          Confines
        </p>
        <p
          className={css.link}
          onClick={() => {
            setData({
              name: "Nahuelbuta",
              img: "https://firebasestorage.googleapis.com/v0/b/saapp-b154e.appspot.com/o/mapas%2Fangol%2FNahuelbuta.png?alt=media&token=350daa2a-29b3-46a0-9ad4-4ecc1273d51d",
            });
          }}
        >
          Nahuelbuta
        </p>
      </div>
      <p className={css.text}>{data?.name}</p>
      <a target="_blank" href={data?.img}>
        <img className={css.img} src={data.img} />
      </a>
    </div>
  );
};

export default Territorios;
