import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import css from "../Footer/Footer.module.css";

const Footer = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    detectColorScheme();
  }, [isDarkMode]);
  function GoUp() {
    window.scrollTo(0, 0);
  }

  function detectColorScheme() {
    var theme = "light"; //default to light
    setIsDarkMode(localStorage.getItem("theme") === "light" ? false : true);
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") == "dark") {
        var theme = "dark";
      }
    } else if (!window.matchMedia) {
      return false;
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      var theme = "dark";
    }
    if (theme == "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    }
  }
  return (
    <div className={css.main}>
      <p
        className={css.link}
        onClick={() => {
          setIsDarkMode(!isDarkMode);
          if (!isDarkMode) {
            localStorage.setItem("theme", "dark");
            document.documentElement.setAttribute("data-theme", "dark");
          } else {
            localStorage.setItem("theme", "light");
            document.documentElement.setAttribute("data-theme", "light");
          }
        }}
      >
        {isDarkMode ? "Modo claro" : "Modo oscuro"}
      </p>
      <Link
        className={css.link}
        to="/admin"
        onClick={() => {
          GoUp();
        }}
      >
        Iniciar sesión
      </Link>
    </div>
  );
};

export default Footer;
