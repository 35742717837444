import React from "react";
import css from "../PredicacionMain/PredicacionMain.module.css";
import PrediSemanal from "../PrediSemanal/PrediSemanal";
import {
  GetPrediData,
  GetPrediDataFiltered,
} from "../../../Services/Firestore/Helper";
import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";

const PredicacionMain = ({
  from = new Date().getTime(),
  to,
  update = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const nav = useNavigate();

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      nav("/carrito");
    },
    onSwipedRight: (eventData) => {
      nav("/mapas");
    },
    delta:70,
  });

  useEffect(() => {
    OnLoad();
  }, [update]);
  useEffect(() => {
    if (to) {
      OrderByWeek();
    }
  }, [from, to]);
  useEffect(() => {
    OrderByWeek();
  }, [data]);
  function OnLoad() {
    if (to) {
      GetPrediData(setIsLoaded, setData);
    } else {
      GetPrediDataFiltered(setIsLoaded, setData);
    }
  }
  window.addEventListener("resize", updateScale);
  updateScale();
  function updateScale() {
    if (window.innerWidth > 800 || window.innerWidth < 600) {
      document.documentElement.style.setProperty("--scale-factor", 1);
    } else {
      const scaleFactor = window.innerWidth / 1000; // Puedes ajustar el valor base según tus necesidades
      document.documentElement.style.setProperty("--scale-factor", scaleFactor);
    }
  }
  function WeekNumber(date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNumber;
  }

  function GroupByWeek(list) {
    const groups = list.reduce(
      (groups, item) => ({
        ...groups,
        [item.week]: [...(groups[item.week] || []), item],
      }),
      {}
    );
    // return groups.sort((a, b) => {
    //   return a[0].date > b[0].date ? 1 : b[0].date > a[0].date ? -1 : 0;
    // });
    return groups;
  }

  function OrderByWeek() {
    if (data.length == 0) return;
    const tmp = data;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i] = {
        ...tmp[i],
        // week: WeekNumber(new Date(tmp[i].año, tmp[i].mes - 1, tmp[i].dia)),
        week: WeekNumber(new Date(tmp[i]?.date)),
      };
    }
    let result;
    if (to) {
      result = GroupByWeek(
        tmp.filter(
          (a) =>
            new Date(a.date).setHours(0, 0, 0, 0) >=
              new Date(from).setHours(0, 0, 0, 0) &&
            new Date(a.date).setHours(0, 0, 0, 0) <=
              new Date(to).setHours(0, 0, 0, 0)
        )
      );
    } else {
      result = GroupByWeek(tmp);
    }
    // console.log(result);
    const week = [];
    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        week.push(result[key]);
      }
    }
    // setWeeks(week);

    const ordered = week.sort((a, b) => {
      return a[0].date > b[0].date ? 1 : b[0].date > a[0].date ? -1 : 0;
    });
    // console.log(ordered);
    setWeeks(ordered);
    // sorted.push(
    // days.sort((a, b) =>
    //   new Date(a[0].año, a[0].mes - 1, a[0].dia).getTime() >
    //   new Date(b[0].año, b[0].mes - 1, b[0].dia).getTime()
    //     ? 1
    //     : new Date(b[0].año, b[0].mes - 1, b[0].dia).getTime() >
    //       new Date(a[0].año, a[0].mes - 1, a[0].dia).getTime()
    //     ? -1
    //     : 0
    // )
  }
  if (isLoaded && weeks.length > 0 && weeks) {
    return (
      <div className={css.main} {...handlers}>
        <p className={css.title}>Programa de predicación</p>
        {weeks.map((week) => {
          return <PrediSemanal key={Math.random()} data={week} />;
        })}
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className={css.main} {...handlers}>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className={css.main} {...handlers}>
        <p className={css.title}>Sin datos</p>
      </div>
    );
  }
};

export default PredicacionMain;
