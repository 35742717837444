import React from "react";
import css from "../Cancion/Cancion.module.css";
import { useState, useEffect } from "react";
import InputText from "../../InputText/InputText";

const Cancion = ({ data, index, editable = false, getData }) => {
  if (data && index && !editable) {
    return (
      <div className={css.main}>
        <img src="../imgs/cancion.png" className={css.img} />
        <p className={css.text}>Canción {data[index]}</p>
      </div>
    );
  }
  if (editable) {
    return (
      <div className={css.editable}>
        <InputText
          initialText={data[index]}
          title="Canción"
          OnChangeText={(text) => {
            if (getData) {
              getData(text);
            }
          }}
        />
        {/* <p className={css.text}>Canción {data[index]}</p> */}
      </div>
    );
  }
};

export default Cancion;
