import React, { useState } from "react";
import css from "../ItemPredicacion/ItemPredicacion.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../../Services/Firebase/Fire";

const ItemPredicacion = ({ data }) => {
  const [isSpecial, setIsSpecial] = useState(false);

  useEffect(() => {
    if (data?.especial) {
      setIsSpecial(true);
    } else {
      setIsSpecial(false);
    }
  }, [data]);
  const placeholder = "../imgs/placeholder.png";

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  function RenderLink() {
    if (isSpecial && data?.link) {
      return (
        <Link className={css.link} to={data?.link} target="_blank">
          {data?.linkText}
        </Link>
      );
    }
  }
  function RenderDatos() {
    if (data?.territorio?.length > 0) {
      let textoFinal = "";
      for (let i = 0; i < data?.territorio?.length; i++) {
        if (i == 0) {
          textoFinal += data?.territorio[i];
        } else {
          textoFinal += ", " + data?.territorio[i];
        }
      }
      return (
        <div
          className={css.datos}
          style={{ display: data?.territorio?.length > 0 ? "" : "none" }}
        >
          <p className={css.dato}>{textoFinal}</p>
        </div>
      );
    }
  }
  if (isSpecial) {
    return (
      <div className={css.event}>
        <div className={css.tipo}>
          <img
            src={data?.img ? data?.img : placeholder}
            className={css.imgEvent}
            style={{ display: data?.img ? "" : "none" }}
            onClick={() => {
              openInNewTab(data?.img);
            }}
          />
          {RenderLink()}
        </div>
        <div
          className={css.detalleEvent}
          style={{ display: data.detalle ? "" : "none" }}
        >
          <p>{data?.detalle}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={css.main}>
        <p
          className={css.hora}
          style={{ display: data?.horario ? "" : "none" }}
        >
          {data?.horario}
        </p>
        <div
          className={css.lugar}
          style={{ display: data?.lugar ? "" : "none" }}
        >
          <img
            src={data?.lugar ? data?.imgLugar : placeholder}
            className={css.img}
          />
          <img src="../imgs/place.webp" className={css.place} />
          <p className={css.texto}>{data?.lugar}</p>
        </div>
        <div className={css.tipo} style={{ display: data.tipo ? "" : "none" }}>
          <img
            src={data?.tipo ? data?.imgTipo : placeholder}
            className={css.img}
          />
          <p className={css.texto}>{data?.tipo}</p>
        </div>
        <div
          className={css.enc}
          style={{ display: data?.encargado ? "" : "none" }}
        >
          <img
            src={data?.encargado ? data?.imgEnc : placeholder}
            className={css.img}
          />
          <p className={css.texto}>Encargado</p>
        </div>
        <div
          className={css.detalle}
          style={{ display: data.detalle ? "" : "none" }}
        >
          <p>{data?.detalle}</p>
        </div>
        {RenderDatos()}
      </div>
    );
  }
};

export default ItemPredicacion;
