import React, { useEffect, useState } from "react";
import css from "../PrediDiaria/PrediDiaria.module.css";
import ItemPredicacion from "../ItemPredicacion/ItemPredicacion";

const PrediDiaria = ({ fecha, data }) => {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    Order();
  }, []);
  function Order() {
    const tmp = data?.sort((a, b) =>
      a.horario > b.horario ? 1 : b.horario > a.horario ? -1 : 0
    );
    setInfo(tmp);
  }
  return (
    <div className={css.main}>
      <h2 className={css.title}>{fecha}</h2>
      {info.map((dia) => {
        return <ItemPredicacion data={dia} key={Math.random()} />;
      })}
    </div>
  );
};

export default PrediDiaria;
