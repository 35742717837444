import React, { useEffect, useState } from "react";
import css from "../Oracion/Oracion.module.css";
import Selector from "../../Admin/Selector/Selector";

const Oracion = ({ data, isEditable = false, getData, formPersonas }) => {
  const [tmp, setTmp] = useState(data);
  useEffect(() => {
    if (data) {
      setTmp(data);
    }
  }, [data]);
  useEffect(() => {
    if (tmp != data && isEditable) {
      getData(tmp);
    }
  }, [tmp]);
  if (!isEditable) {
    return (
      <div className={css.main}>
        <p className={css.text}>Oración</p>
        <img
          style={{ display: data.img ? "" : "none" }}
          src={data.img ? data?.img : "../imgs/placeholder.png"}
          className={css.img}
        />
      </div>
    );
  } else {
    return (
      <div className={css.main}>
        <p className={css.text}>Oración</p>
        <div className={css.inputs}>
          <Selector
            optionsObj={formPersonas}
            selectedValue={tmp?.name}
            returnValue={(val) => {
              setTmp({ ...data, name: val });
            }}
          />
        </div>
      </div>
    );
  }
};

export default Oracion;
