import React from "react";
import css from "../Nav/Nav.module.css";
import { Link, NavLink } from "react-router-dom";

const Nav = () => {
  function GoUp() {
    window.scrollTo(0, 0);
  }
  return (
    <nav className={css.nav}>
      <NavLink
        className={({ isActive }) => (isActive ? css.active : css.link)}
        to="/"
        onClick={() => {
          GoUp();
        }}
      >
        Predicación
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? css.active : css.link)}
        to="/carrito"
        onClick={() => {
          GoUp();
        }}
      >
        Carrito
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? css.active : css.link)}
        to="/vmc"
        onClick={() => {
          GoUp();
        }}
      >
        Reuniones
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? css.active : css.link)}
        to="/mapas"
        onClick={() => {
          GoUp();
        }}
      >
        Mapas
      </NavLink>
    </nav>
  );
};

export default Nav;
