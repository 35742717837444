import React, { useEffect } from "react";
import { useState } from "react";
import {
  GetCarritoData,
  GetCarritoFiltered,
} from "../../Services/Firestore/Helper";
import css from "../Carrito/Carrito.module.css";
import ItemCarrito from "../Predicacion/ItemCarrito/ItemCarrito";
import CarritoContainer from "../Predicacion/CarritoContainer/CarritoContainer";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Spinner from "../Spinner/Spinner";

const Carrito = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState([]);
  useEffect(() => {
    GetCarritoFiltered(setIsLoaded, setContent);
  }, []);
  useEffect(() => {
    // console.log(content);
  }, [content]);
  const nav = useNavigate();
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      nav("/vmc");
    },
    onSwipedRight: (eventData) => {
      nav("/");
    },
    delta:70,
  });

  if (isLoaded && content.length > 0) {
    return (
      <div className={css.main} {...handlers}>
        <p className={css.title}>Predicación Carrito</p>
        {content.map((data) => {
          return <CarritoContainer data={data} key={Math.random()} />;
        })}
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className={css.main} {...handlers}>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className={css.main} {...handlers}>
        <p className={css.title}>Sin datos</p>
      </div>
    );
  }
};

export default Carrito;
