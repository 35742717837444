import React from "react";
import css from "../Spinner/Spinner.module.css";

const Spinner = ({ pageSize = true, isVisible = true }) => {
  if (!isVisible) {
    return <></>;
  } else {
    return (
      <div className={pageSize ? css.main : ""}>
        <div className={css.lds}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
};

export default Spinner;
