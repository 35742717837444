import React, { useEffect, useState } from "react";
import css from "../Semana/Semana.module.css";
import DiaSemana from "../DiaSemana/DiaSemana";
import FDS from "../FDS/FDS";

const Semana = ({ data, filtered }) => {
  const [tmp, setTmp] = useState([]);
  useEffect(() => {
    if (data) {
      setTmp(data);
    }
  }, [data]);

  function Render() {
    if (tmp) {
      return tmp.map((item) => {
        return (
          <div key={item?.date} className={css.items}>
            <DiaSemana data={item?.es} date={item?.date} />
            <FDS data={item?.fds} date={item?.date} />
          </div>
        );
      });
    }
  }
  return <div className={css.main}>{Render()}</div>;
};

export default Semana;
