import React from "react";
import css from "../ItemCarrito/ItemCarrito.module.css";

const ItemCarrito = ({ data }) => {
  const placeholder = "../imgs/placeholder.png";
  if (data) {
    return (
      <div className={css.main}>
        <p
          className={css.hora}
          style={{ display: data?.horario ? "" : "none" }}
        >
          {data?.horario}
        </p>
        <div
          className={css.lugar}
          style={{ display: data?.lugar ? "" : "none" }}
        >
          <img
            src={data?.lugar ? data?.imgLugar : placeholder}
            className={css.img}
          />
          <img src="../imgs/place.webp" className={css.place} />
          <p className={css.texto}>{data?.lugar}</p>
        </div>
        <div
          className={css.enc}
          style={{ display: data?.persona1 ? "" : "none" }}
        >
          <img
            src={data?.imgPersona1 ? data?.imgPersona1 : placeholder}
            className={css.img}
          />
          <p className={css.texto}>{data?.persona1}</p>
        </div>
        <div
          className={css.aux}
          style={{ display: data?.persona2 ? "" : "none" }}
        >
          <img
            src={data?.imgPersona2 ? data?.imgPersona2 : placeholder}
            className={css.img}
          />
          <p className={css.texto}>{data?.persona2}</p>
        </div>
      </div>
    );
  }
};

export default ItemCarrito;
