import React from "react";
import { useState, useEffect } from "react";
import css from "../Selector/Selector.module.css";

const Selector = ({
  optionsList,
  returnValue,
  title,
  optionsObj,
  isVisible,
  selectedValue,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (optionsList) {
      const tmp = optionsList.sort();
      setOptions(tmp);
    }
    if (optionsObj) {
      const tmp = optionsObj.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setOptions(tmp);
    }
  }, [optionsList, optionsObj]);
  useEffect(() => {
    if (selectedValue) {
      setCurrentValue(selectedValue);
    } else {
      setCurrentValue("");
    }
  }, [isVisible, selectedValue]);

  function AddOptions() {
    if (optionsList) {
      return optionsList.map((option) => {
        return <option key={option}>{option}</option>;
      });
    }
    if (optionsObj) {
      return optionsObj.map((option) => {
        return <option key={option.name}>{option.name}</option>;
      });
    }
  }

  return (
    <div className={css.main}>
      <p className={css.title}>{title}</p>
      <select
        className={css.selector}
        value={currentValue}
        onChange={(e) => {
          setCurrentValue(e.target.value);
          if (e.target.value) {
            returnValue(e.target.value);
          } else {
            returnValue("");
          }
        }}
      >
        <option></option>
        {AddOptions()}
      </select>
    </div>
  );
};

export default Selector;
