import React, { useEffect, useState } from "react";
import css from "../Btn/Btn.module.css";

const Btn = ({ onSave, onDiscard }) => {
  function Save() {
    if (!onSave) return;
    onSave();
  }
  function Discard() {
    if (!onDiscard) return;
    onDiscard();
  }
  return (
    <div className={css.container}>
      <p
        className={`${css.btn} ${css.discard}`}
        onClick={() => {
          Discard();
        }}
      >
        Descartar
      </p>
      <p
        className={`${css.btn} ${css.save}`}
        onClick={() => {
          Save();
        }}
      >
        Guardar
      </p>
    </div>
  );
};

export default Btn;
