import React, { useEffect, useState } from "react";
import css from "../VMCEditor/VMCEditor.module.css";
import SelectorSemana from "./SelectorSemana/SelectorSemana";
import VMCWindow from "./VMCWindow/VMCWindow";
import {
  DeleteVMCWithKey,
  GetFormData,
  GetVMC,
  SaveNewVMC,
  SaveVMCWithKey,
} from "../../Services/Firestore/Helper";
import VMCList from "./VMCList/VMCList";
import Btn from "../Btn/Btn";
import SingleBtn from "../SingleBtn/SingleBtn";
import { GetWeekNumber } from "../../Utils/Utilidad";
import Spinner from "../Spinner/Spinner";

const VMCEditor = () => {
  // VARIABLES
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState([]);
  const [week, setWeek] = useState(0);
  const [displayWindow, setDisplayWindow] = useState(false);
  const [currentWeekData, setCurrentWeekData] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const empty = {
    año: "",
    key: "",
    fds: {
      proyeccion: {
        name: "",
        img: "",
      },
      especial: "",
      traslado: {
        img: "",
        name: "",
      },
      status: true,
      aseo: "",
      apoyo: {
        img: "",
        name: "",
      },
      presidencia: {
        img: "",
        name: "",
      },
      acomodacion: {
        name: "",
        img: "",
      },
      discurso: {
        tema: "",
        img: "",
      },
      camara: {
        img: "",
        name: "",
      },
    },
    semana: "",
    es: {
      lectura: "",
      especial: "",
      traslado: {
        img: "",
        name: "",
      },
      status: true,
      camara: {
        name: "",
        img: "",
      },
      apoyo: {
        img: "",
        name: "",
      },
      acomodacion: {
        name: "",
        img: "",
      },
      proyeccion: {
        img: "",
        name: "",
      },
      discursos: [
        {
          numero: 0,
          numToShow: 0,
          img: "",
          tema: "Palabras de introducción (1 min.)",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "tesoros",
          status: true,
        },
        {
          numero: 1,
          numToShow: 1,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "tesoros",
          status: true,
        },
        {
          numero: 2,
          numToShow: 2,
          img: "",
          tema: "Busquemos perlas escondidas (10 mins.)",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "tesoros",
          status: true,
        },
        {
          numero: 3,
          numToShow: 3,
          img: "",
          tema: "Lectura de la Biblia (4 mins.)",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "tesoros",
          status: true,
        },
        {
          numero: 4,
          numToShow: 4,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "maestros",
          status: true,
        },
        {
          numero: 5,
          numToShow: 5,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "maestros",
          status: true,
        },
        {
          numero: 6,
          numToShow: 6,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "maestros",
          status: true,
        },
        {
          numero: 7,
          numToShow: 7,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "maestros",
          status: true,
        },
        {
          numero: 8,
          numToShow: 8,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "vida",
          status: true,
        },
        {
          numero: 9,
          numToShow: 9,
          img: "",
          tema: "",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "vida",
          status: true,
        },
        {
          numero: 10,
          numToShow: 10,
          img: "",
          tema: "Estudio bíblico de la congregación (30 mins.)",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "vida",
          status: true,
        },
        {
          numero: 11,
          numToShow: 11,
          img: "",
          tema: "Palabras de conclusión (3 mins.)",
          encargado: {
            img: "",
            name: "",
          },
          encargado2: {
            img: "",
            name: "",
          },
          tipo: "vida",
          status: true,
        },
      ],
      aseo: "",
      oracion: {
        img: "",
        name: "",
      },
      canticos: ["", "", ""],
    },
  };

  // USEEFFECTS
  useEffect(() => {
    GetData();
  }, []);
  useEffect(() => {
    if (!isLoaded) {
      let result = GetWeekNumber(new Date());
      setWeek(result.week);
      FilterByWeek(result.week, result.lunes);
      // console.log("1");
    } else {
      FilterByWeek(week);
      // console.log("2");
    }
  }, [content]);

  // FUNCIONES

  function FilterByWeek(week, monday) {
    const lunes = new Date(monday);
    lunes.setHours(0, 0, 0, 0);
    const tmp = content.filter((item) => item.semana === week);
    if (tmp.length > 0) {
      setCurrentWeekData(...tmp);
      setIsEmpty(false);
    } else {
      setCurrentWeekData({
        ...empty,
        semana: week,
        date: lunes?.getTime(),
        año: lunes?.getFullYear(),
      });
      setIsEmpty(true);
    }
  }

  function GetData() {
    GetVMC(setIsLoaded, setContent);
    GetFormData(setIsLoaded, setFormData);
  }
  function OnAdd() {
    setDisplayWindow(true);
  }
  function OnSave(data) {
    const tmp = OrderDisc(data);
    if (tmp.key != "") {
      SaveVMCWithKey(tmp?.key, tmp, GetData);
    } else {
      SaveNewVMC(tmp, GetData);
    }
  }
  function OrderDisc(data) {
    const tmpData = Ordenar(data.es.discursos);
    if (!tmpData) return;
    let count = 0;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].status) {
        tmpData[i].numToShow = count;
        count++;
      }
    }
    return { ...data, es: { ...data.es, discursos: tmpData } };
    // setTmp({ ...tmp, es: { ...tmp.es, discursos: tmpData } });
  }
  function Ordenar(data) {
    if (!data) return;
    return data.sort((a, b) =>
      a.numero > b.numero ? 1 : b.numero > a.numero ? -1 : 0
    );
  }
  function OnDiscard(data) {
    if (data.key != "") {
      DeleteVMCWithKey(data?.key, data, GetData);
    } else {
      GetData();
    }
  }

  // RENDERING

  if (isLoaded) {
    return (
      <div className={css.main}>
        <p className={css.title}>Reuniones</p>
        <p className={css.title}>{week}</p>
        <SelectorSemana
          GetWeek={(week, monday) => {
            if (week && monday) {
              FilterByWeek(week, monday);
              setWeek(week);
            }
          }}
          OnAdd={() => {
            OnAdd();
          }}
        />
        <p className={css.title}>{!isEmpty ? "" : "Sin datos"}</p>
        <VMCList
          data={currentWeekData}
          formdata={formData}
          onSave={(data) => {
            // setTmp(data);
            OnSave(data);
          }}
          onDelete={(data) => {
            OnDiscard(data);
          }}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default VMCEditor;
