import React, { useEffect, useState } from "react";
import css from "../SelectorSemana/SelectorSemana.module.css";
import SingleBtn from "../../SingleBtn/SingleBtn";
import { GetWeekNumber } from "../../../Utils/Utilidad";

const SelectorSemana = ({ GetWeek }) => {
  const [date, setDate] = useState(new Date());
  const [lunes, setLunes] = useState(new Date());
  const [domingo, setDomingo] = useState(new Date());
  const [displayWindow, setDisplayWindow] = useState(false);
  useEffect(() => {
    OnChangeWeekNumber();
  }, [date]);
  useEffect(() => {
    CalculateThisWeek();
  }, []);
  function AddWeek() {
    const fecha = new Date(date);
    fecha.setDate(date.getDate() + 7);
    setDate(fecha);
  }
  function RemoveWeek() {
    const fecha = new Date(date);
    fecha.setDate(date.getDate() - 7);
    setDate(fecha);
  }
  function OnChangeWeekNumber() {
    let result = GetWeekNumber(date);
    setLunes(result.lunes);
    setDomingo(result.domingo);
    GetWeek(result.week, result.lunes);
  }
  function CalculateThisWeek() {
    let result = GetWeekNumber(new Date());
    setLunes(result.lunes);
    setDomingo(result.domingo);
    GetWeek(result.week, result.lunes);
  }

  return (
    <div className={css.main}>
      <p>Semana</p>
      <div className={css.row}>
        <p
          className={css.btn}
          onClick={() => {
            RemoveWeek();
          }}
        >
          ◀
        </p>
        <p
          className={css.date}
        >{`Del ${lunes.getDate()} de ${lunes.toLocaleString("es-CL", {
          month: "long",
        })} al ${domingo.getDate()} de ${domingo.toLocaleString("es-CL", {
          month: "long",
        })} `}</p>
        <p
          className={css.btn}
          onClick={() => {
            AddWeek();
          }}
        >
          ▶
        </p>
      </div>
      {/* <SingleBtn
        text="Añadir"
        isSave={true}
        onClick={() => {
          OnAdd();
        }}
      /> */}
    </div>
  );
};

export default SelectorSemana;
