import React from "react";
import css from "../InputText/InputText.module.css";
import { useState, useEffect } from "react";

const InputText = ({
  placeholder = "",
  initialText = "",
  OnChangeText,
  isVisible,
  title = "",
  isTextArea = false,
}) => {
  const [text, setText] = useState("");
  useEffect(() => {
    setText(initialText);
  }, [isVisible]);
  function OnChange(text) {
    setText(text);
  }
  useEffect(() => {
    if (initialText != text) {
      setText(initialText);
    }
  }, [initialText]);

  if (isTextArea) {
    return (
      <div className={css.main}>
        <p className={css.text}>{title}</p>
        <textarea
          type="text"
          className={css.textarea}
          value={text}
          placeholder={placeholder}
          onBlur={(e) => {
            OnChangeText(e.target.value);
          }}
          onChange={(e) => {
            OnChange(e.target.value);
          }}
        />
      </div>
    );
  } else {
    return (
      <div className={css.main}>
        <p className={css.text}>{title}</p>
        <input
          type="text"
          className={css.input}
          value={text}
          placeholder={placeholder}
          onBlur={(e) => {
            OnChangeText(e.target.value);
          }}
          onChange={(e) => {
            OnChange(e.target.value);
          }}
        />
      </div>
    );
  }
};

export default InputText;
