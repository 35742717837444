import React, { useEffect, useState } from "react";
import css from "../Datos/Datos.module.css";
import Selector from "../Admin/Selector/Selector";
import SingleBtn from "../SingleBtn/SingleBtn";
import InputText from "../InputText/InputText";
import Btn from "../Btn/Btn";
import {
  DeleteDatoWithKey,
  GetDatos,
  GetDatosByCity,
  GetFormData,
  SaveDatoWithKey,
  SaveNewDato,
} from "../../Services/Firestore/Helper";
import DateSelector from "../DateSelector/DateSelector";

const Datos = () => {
  const empty = {
    name: "",
    city: "",
    detail: "",
    adress: "",
    lat: "",
    lon: "",
    status: "",
    conductor: "",
    nopasar: false,
  };
  const [selectedCity, setSelectedCity] = useState("");
  const [editionWindow, setEditionWindow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(empty);
  const [isEdition, setIsEdition] = useState(true);
  const [formData, setFormData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [noPasarCount, setNoPasarCount] = useState(0);
  const [estudianCount, setEstudianCount] = useState(0);
  const [isVisitWindow, setIsVisitWindow] = useState(false);
  const [currentVisita, setCurrentVisita] = useState({
    fecha: new Date().getTime(),
    nc: false,
    detalle: "",
  });
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    console.log(currentVisita);
  }, [currentVisita]);
  useEffect(() => {
    console.log(selectedItem);
  }, [selectedItem]);
  useEffect(() => {
    // console.log(datos);
    let tmp = datos.filter((item) => item?.nopasar);
    setNoPasarCount(tmp?.length);
    let tmp2 = datos.filter((item) => item?.conductor != "");
    setEstudianCount(tmp2?.length);
  }, [datos]);
  useEffect(() => {
    GetFormData(setIsLoaded, setFormData);
  }, []);
  useEffect(() => {
    Reload();
  }, [selectedCity]);

  function Reload() {
    setEditionWindow(false);
    setIsVisitWindow(false);
    if (selectedCity == "Todos") {
      GetDatos(setIsLoaded, setDatos);
    } else {
      GetDatosByCity(setIsLoaded, setDatos, selectedCity);
    }
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  function AddNew() {
    setSelectedItem({ ...empty, city: selectedCity ? selectedCity : "" });
    setEditionWindow(true);
    setIsEdition(false);
  }
  function OnEdit(key) {
    let temp = datos.find((item) => item.key == key);
    setIsEdition(true);
    setSelectedItem({ ...temp });
    setEditionWindow(true);
  }
  function OnVisit(key) {
    setCurrentVisita({ fecha: new Date().getTime(), nc: false, detalle: "" });
    let temp = datos.find((item) => item.key == key);
    setIsVisitWindow(true);
    setSelectedItem({ ...temp });
  }
  function RenderVisitWindow() {
    if (isVisitWindow) {
      return (
        <div className={css.bg}>
          <div className={css.window}>
            <div className={css.content}>
              <p className={css.title}>Añadir Visita</p>
              <p className={css.text}>Nombre: {selectedItem?.name}</p>
              <p className={css.text}>Ciudad: {selectedItem?.city}</p>
              <div className={css.question}>
                <p className={css.text}>No en casa</p>
                <label className={css.check}>
                  <input
                    type="checkbox"
                    checked={currentVisita?.nc || false}
                    onChange={(e) => {
                      setCurrentVisita({
                        ...currentVisita,
                        nc: e.target.checked,
                      });
                    }}
                  />
                </label>
              </div>
              <DateSelector
                onChange={(date) => {
                  setCurrentVisita({
                    ...currentVisita,
                    fecha: date?.getTime(),
                  });
                }}
              />
              <InputText
                title="Detalle"
                isTextArea={true}
                OnChangeText={(text) => {
                  setCurrentVisita({
                    ...currentVisita,
                    detalle: text,
                  });
                }}
                initialText={currentVisita?.detalle}
              />

              <Btn
                onDiscard={() => {
                  setIsVisitWindow(false);
                }}
                onSave={() => {
                  if (!selectedItem?.visitas) {
                    let visitas = [currentVisita];
                    const tmp = {
                      ...selectedItem,
                      visitas: visitas,
                    };
                    SaveDatoWithKey(selectedItem?.key, tmp, () => {
                      Reload();
                    });
                  } else {
                    let visitas = [...selectedItem?.visitas];
                    visitas.push(currentVisita);
                    const tmp = {
                      ...selectedItem,
                      visitas: visitas,
                    };
                    SaveDatoWithKey(selectedItem?.key, tmp, () => {
                      Reload();
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
  function RenderWindow() {
    if (editionWindow) {
      return (
        <div className={css.bg}>
          <div className={css.window}>
            <div className={css.content}>
              <p className={css.title}>
                {isEdition ? "Editar Dato" : "Añadir Dato"}
              </p>
              <div className={css.question}>
                <p className={css.text}>Es no pasar?</p>
                <label className={css.check}>
                  <input
                    type="checkbox"
                    checked={selectedItem?.nopasar || false}
                    onChange={(e) => {
                      setSelectedItem({
                        ...selectedItem,
                        nopasar: e.target.checked,
                      });
                    }}
                  />
                </label>
              </div>
              <Selector
                isVisible={true}
                optionsList={[
                  "Angol",
                  "Collipulli",
                  "Nacimiento",
                  "Puren",
                  "Renaico",
                  "Tijeral",
                ]}
                selectedValue={selectedItem?.city}
                title={"Lugar"}
                returnValue={(val) => {
                  setSelectedItem({
                    ...selectedItem,
                    city: val,
                  });
                }}
              />

              <Selector
                isVisible={true}
                optionsObj={formData.personas}
                selectedValue={selectedItem?.city}
                title={"Estudia con"}
                returnValue={(val) => {
                  setSelectedItem({
                    ...selectedItem,
                    conductor: val,
                  });
                }}
              />
              <InputText
                title="Nombre"
                OnChangeText={(text) => {
                  setSelectedItem({
                    ...selectedItem,
                    name: text,
                  });
                }}
                initialText={selectedItem?.name}
              />
              <InputText
                title="Descripción"
                OnChangeText={(text) => {
                  setSelectedItem({
                    ...selectedItem,
                    detail: text,
                  });
                }}
                initialText={selectedItem?.detail}
              />
              <InputText
                title="Dirección"
                OnChangeText={(text) => {
                  setSelectedItem({
                    ...selectedItem,
                    adress: text,
                  });
                }}
                initialText={selectedItem?.adress}
              />
              <InputText
                title="Latitud"
                OnChangeText={(text) => {
                  setSelectedItem({
                    ...selectedItem,
                    lat: text,
                  });
                }}
                initialText={selectedItem?.lat}
              />
              <InputText
                title="Longitud"
                OnChangeText={(text) => {
                  setSelectedItem({
                    ...selectedItem,
                    lon: text,
                  });
                }}
                initialText={selectedItem?.lon}
              />
              <SingleBtn
                text={"Estoy en la ubicación"}
                isSave={true}
                onClick={() => {
                  navigator.geolocation.getCurrentPosition((geo) => {
                    setSelectedItem({
                      ...selectedItem,
                      lat: geo.coords.latitude,
                      lon: geo.coords.longitude,
                    });
                  });
                }}
              />
              <SingleBtn
                text={"Abrir en el mapa"}
                isSave={true}
                onClick={() => {
                  openInNewTab(
                    `https://www.google.com/maps?q=${selectedItem.lat},${selectedItem.lon}`
                  );
                }}
              />
              <Btn
                onDiscard={() => {
                  setEditionWindow(false);
                }}
                onSave={() => {
                  if (isEdition) {
                    SaveDatoWithKey(selectedItem?.key, selectedItem, () => {
                      Reload();
                    });
                  } else {
                    SaveNewDato(selectedItem, () => {
                      Reload();
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
  function RenderList() {
    if (datos?.length > 0) {
      return (
        <div className={css.list}>
          {datos?.map((item) => {
            return (
              <div
                className={css.item}
                key={item?.name + item?.detail}
                style={{ backgroundColor: item?.nopasar ? "#cf4c48" : "" }}
              >
                <div className={css.col}>
                  <p className={css.textData}>
                    {selectedCity == "Todos" ? "Ciudad: " + item?.city : ""}
                  </p>
                  <p className={css.textData}>{item?.name}</p>
                  <p className={css.textData}>Direccion: {item?.adress}</p>
                  <p className={css.textData}>Detalle: {item?.detail}</p>
                  <p className={css.textData}>
                    {item?.conductor != ""
                      ? "Estudia con " + item?.conductor
                      : "Estudia: No"}
                  </p>
                  <p className={css.textData}>
                    {item?.nopasar ? "No pasar" : ""}
                  </p>
                  <div className={css.row}>
                    <SingleBtn
                      text={"Visitar"}
                      isSave={true}
                      onClick={() => {
                        OnVisit(item?.key);
                      }}
                    />
                    <SingleBtn
                      text={"Mapa"}
                      isSave={true}
                      onClick={() => {
                        openInNewTab(
                          `https://www.google.com/maps?q=${item?.lat},${item.lon}`
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={css.col}>
                  <SingleBtn
                    isSave={true}
                    text={"Editar"}
                    onClick={() => {
                      OnEdit(item?.key);
                    }}
                  />
                  <SingleBtn
                    isSave={false}
                    text={"Eliminar"}
                    onClick={() => {
                      DeleteDatoWithKey(item?.key, Reload);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p className={css.text}>Sin datos</p>;
    }
  }
  // NORMAL
  return (
    <div className={css.main}>
      <p className={css.title}>Datos</p>
      <Selector
        isVisible={true}
        optionsList={[
          "Todos",
          "Angol",
          "Collipulli",
          "Nacimiento",
          "Puren",
          "Renaico",
          "Tijeral",
        ]}
        title={"Ciudad"}
        returnValue={(val) => {
          setSelectedCity(val);
          setSelectedItem({ ...selectedItem, city: val });
        }}
      />
      <SingleBtn
        isSave={true}
        text={"Agregar Dato"}
        onClick={() => {
          AddNew();
        }}
      />
      <div
        className={css.row}
        style={{ display: datos?.length > 0 ? "" : "none" }}
      >
        <p className={css.text}>Cantidad: {datos?.length}</p>
        <p className={css.text}>No pasar: {noPasarCount}</p>
        <p className={css.text}>Estudian: {estudianCount}</p>
      </div>
      {RenderWindow()}
      {RenderVisitWindow()}
      {RenderList()}
    </div>
  );
};

export default Datos;
