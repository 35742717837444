import React, { useEffect, useState } from "react";
import css from "../VMCPage/VMCPage.module.css";
import VMCEditor from "../../VMCEditor/VMCEditor";
import SingleBtn from "../../SingleBtn/SingleBtn";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../../Spinner/Spinner";

const VMCPage = () => {
  const [logged, setLogged] = useState();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogged(true);
      } else {
        setLogged(false);
        navigate("/admin");
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  if (logged) {
    return (
      <div className={css.main}>
        <h1 className={css.title}>Editor de VMC</h1>
        <SingleBtn
          isSave={true}
          text={"Ver programa por fechas"}
          onClick={() => {
            navigate("/reuniones");
          }}
        />
        <VMCEditor />
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default VMCPage;
