import React, { useEffect } from "react";
import css from "../Discurso/Discurso.module.css";
import InputText from "../../InputText/InputText";
import Selector from "../../Admin/Selector/Selector";
import { useState } from "react";
import Resizer from "react-image-file-resizer";
import {
  getDownloadURL,
  uploadString,
  ref,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../Services/Firebase/Fire";
import FileUploader from "../../FileUploader/FileUploader";

const Discurso = ({
  data,
  isEditable = false,
  getData,
  formPersonas,
  updatedNumber,
  semana,
}) => {
  const [tmp, setTmp] = useState(data);
  const storageRef = ref(storage, `vmc/${semana}/${data?.numero}.webp`);
  useEffect(() => {
    if (data) {
      setTmp(data);
    }
  }, [data]);
  useEffect(() => {
    if (tmp != data && isEditable) {
      getData(tmp);
    }
  }, [tmp]);

  function RenderApoyo() {
    if (data?.encargado2.img && data?.numero == "3") {
      return <p className={css.apoyo}>Apoyo</p>;
    }
  }
  function ResetImg() {
    if (tmp?.img != "") {
      deleteObject(storageRef)
        .then(() => {})
        .catch((error) => {})
        .finally(() => {
          setTmp({ ...tmp, img: "" });
        });
    }
  }

  if (!isEditable) {
    if (data?.status) {
      return (
        <div className={css.main}>
          <div className={css.id}>
            <p
              className={`${css.number} ${
                data?.tipo === "tesoros"
                  ? css.tesoros
                  : data?.tipo === "maestros"
                  ? css.maestros
                  : data?.tipo === "vida"
                  ? css.vida
                  : ""
              }`}
            >
              {data?.numToShow == "0" ? "" : data?.numToShow}
            </p>
            <img
              style={{ display: data?.img ? "" : "none" }}
              src={data?.img ? data?.img : "../imgs/placeholder.png"}
              className={css.img}
            />
          </div>
          <p className={css.text}>{data?.tema}</p>
          <div className={css.id}>
            <img
              style={{ display: data?.encargado.img ? "" : "none" }}
              src={
                data?.encargado.img
                  ? data?.encargado.img
                  : "../imgs/placeholder.png"
              }
              className={css.img}
            />
            <img
              style={{ display: data?.encargado2.img ? "" : "none" }}
              src={
                data?.encargado2.img
                  ? data?.encargado2.img
                  : "../imgs/placeholder.png"
              }
              className={css.img}
            />
            {RenderApoyo()}
          </div>
        </div>
      );
    }
  } else {
    return (
      <div
        className={css.editable}
        style={{
          filter: data?.status ? "grayscale(0)" : "grayscale(1)",
          backgroundColor: data?.status ? "" : "#555",
        }}
      >
        <div className={css.idEditable}>
          <p
            className={`${css.numberEdit} ${
              data?.tipo === "tesoros"
                ? css.tesoros
                : data?.tipo === "maestros"
                ? css.maestros
                : data?.tipo === "vida"
                ? css.vida
                : ""
            }`}
            onClick={() => {
              setTmp({ ...tmp, status: !tmp.status });
              if (updatedNumber) {
                updatedNumber();
              }
            }}
          >
            {data?.numToShow == "0" ? "" : data?.numToShow}
          </p>
        </div>
        <img
          src="/imgs/borrar.png"
          className={css.delete}
          style={{ display: data?.img ? "" : "none" }}
          onClick={() => {
            ResetImg();
          }}
        />
        <FileUploader
          archivo={`${data?.numero}.webp`}
          currentImg={tmp?.img}
          ruta={`vmc/${semana}`}
          fileType={"image/png, image/jpeg"}
          resolutionX={150}
          resolutionY={150}
          returnURL={(url) => {
            setTmp({ ...tmp, img: url });
          }}
        />

        <InputText
          initialText={data?.tema}
          isTextArea={true}
          placeholder="Titulo del discurso"
          OnChangeText={(text) => {
            if (getData) {
              setTmp({ ...tmp, tema: text });
            }
          }}
        />

        <div className={css.inputs}>
          <Selector
            optionsObj={formPersonas}
            selectedValue={data?.encargado?.name}
            returnValue={(val) => {
              setTmp({ ...data, encargado: val });
            }}
          />
        </div>
        <div className={css.inputs}>
          <Selector
            optionsObj={formPersonas}
            selectedValue={data?.encargado2?.name}
            returnValue={(val) => {
              setTmp({ ...data, encargado2: val });
            }}
          />
        </div>
      </div>
    );
  }
};

export default Discurso;
