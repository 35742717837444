import "./App.css";
import Nav from "./Components/Nav/Nav";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import PredicacionMain from "./Components/Predicacion/PredicacionMain/PredicacionMain";
import VMCMain from "./Components/VMC/VMCMain/VMCMain";
import Admin from "./Components/Admin/Admin";
import Carrito from "./Components/Carrito/Carrito";
import Footer from "./Components/Footer/Footer";
import PrediRange from "./Components/Predicacion/PrediRange/PrediRange";
import VMCRange from "./Components/VMC/VMCRange/VMCRange";
import Territorios from "./Components/Territorios/Territorios";
import { useEffect } from "react";
import VMCPage from "./Components/Admin/VMCPage/VMCPage";
import SSPage from "./Components/Admin/SSPage/SSPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route exact path="/predicacion" element={<PrediRange />}></Route>
          <Route exact path="/reuniones" element={<VMCRange />}></Route>
          <Route exact path="/mapas" element={<Territorios />}></Route>
          <Route exact path="/" element={<PredicacionMain />}></Route>
          <Route exact path="/carrito" element={<Carrito />}></Route>
          <Route exact path="/vmc" element={<VMCMain />}></Route>
          <Route exact path="/admin" element={<Admin />}></Route>
          <Route exact path="/meeting" element={<VMCPage />}></Route>
          <Route exact path="/ss" element={<SSPage />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
