import React, { useRef } from "react";
import Resizer from "react-image-file-resizer";
import css from "../FileUploader/FileUploader.module.css";
import { storage } from "../../Services/Firebase/Fire";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { useState } from "react";
import { useEffect } from "react";

const FileUploader = ({
  ruta,
  archivo,
  returnURL,
  currentImg = "",
  fileType,
  resolutionX,
  resolutionY,
}) => {
  const [img, setImg] = useState(currentImg);
  const storageRef = ref(storage, `${ruta}/${archivo}`);
  useEffect(() => {
    setImg(currentImg);
  }, [currentImg]);
  useEffect(() => {
    // console.log(archivo);
  }, [archivo]);

  return (
    <label className="fileSelector">
      <img src={img ? img : "../imgs/subir.png"} className={css.imgEdit} />
      <input
        type="file"
        className="fileInput"
        accept={fileType}
        onChange={(e) => {
          // console.log(e.target.files);
          if (e.target.files.length == 1) {
            const reader = new FileReader();
            reader.addEventListener("load", (event) => {
              if (!e.target.files[0]) return;
              Resizer.imageFileResizer(
                e.target.files[0], // Is the file of the image which will resized.
                resolutionX, // Is the maxWidth of the resized new image.
                resolutionY, // Is the maxHeight of the resized new image.
                "webp", // Is the compressFormat of the resized new image.
                90, // Is the quality of the resized new image.
                0, // Is the degree of clockwise rotation to apply to uploaded image.
                (uri) => {
                  // console.log(storageRef.fullPath);
                  uploadString(storageRef, uri, "data_url").then((snapshot) => {
                    // console.log(snapshot);
                    if (returnURL) {
                      getDownloadURL(snapshot.ref).then((downloadUrl) => {
                        returnURL(downloadUrl);
                      });
                    }
                  });
                }, // Is the callBack function of the resized new image URI.
                "base64" // Is the output type of the resized new image.
              );
            });
            reader.readAsDataURL(e.target.files[0]);
          }
        }}
      />
    </label>
  );
};

export default FileUploader;
