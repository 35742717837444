import React, { useState } from "react";
import css from "../VMCList/VMCList.module.css";
import { useEffect } from "react";
import Discurso from "../../VMC/Discurso/Discurso";
import Privilegio from "../../VMC/Privilegio/Privilegio";
import Selector from "../../Admin/Selector/Selector";
import Cancion from "../../VMC/Cancion/Cancion";
import Oracion from "../../VMC/Oracion/Oracion";
import InputText from "../../InputText/InputText";
import SingleBtn from "../../SingleBtn/SingleBtn";
import Resizer from "react-image-file-resizer";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { storage } from "../../../Services/Firebase/Fire";
import FileUploader from "../../FileUploader/FileUploader";
import { GetScrapperURL } from "../../../Services/Firestore/Helper";

const VMCList = ({ data, formdata, onDelete, onSave }) => {
  const [esObj, setEsObj] = useState();
  const [fdsObj, setFdsObj] = useState();
  const [canSave, setCanSave] = useState(false);
  const storageRef = ref(storage, `vmc/${data?.semana}/discursante.webp`);
  useEffect(() => {
    LoadData();
    // console.log(data);
  }, [data]);
  useEffect(() => {
    if (deepEqual(data.es, esObj) && deepEqual(data.fds, fdsObj)) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [esObj, fdsObj]);

  function LoadData() {
    if (data) {
      setFdsObj(JSON.parse(JSON.stringify(data.fds)));
      setEsObj(JSON.parse(JSON.stringify(data.es)));
    }
  }
  function GetPersonData(name) {
    const result = formdata.personas.find((item) => item.name == name);
    if (result) {
      return result;
    } else {
      return "";
    }
  }
  function deepEqual(x, y) {
    return x && y && typeof x === "object" && typeof y === "object"
      ? Object.keys(x).length === Object.keys(y).length &&
          Object.keys(x).reduce(function (isEqual, key) {
            return isEqual && deepEqual(x[key], y[key]);
          }, true)
      : x === y;
  }

  function SaveDisc(data) {
    // console.log(data);
    let name1 = GetPersonData(data?.encargado);
    let name2 = GetPersonData(data?.encargado2);
    let fixedData = { ...data };
    if (name1) {
      fixedData = {
        ...data,
        encargado: name1,
        encargado2: data?.encargado2,
      };
    }
    if (name2) {
      fixedData = {
        ...data,
        encargado: data?.encargado,
        encargado2: name2,
      };
    }
    const all = [...esObj.discursos];
    const index = all.findIndex((e) => e?.numero === fixedData?.numero);
    if (index != -1) {
      all[index] = fixedData;
      setEsObj({
        ...esObj,
        discursos: [...all].sort((a, b) =>
          a.numero > b.numero ? 1 : b.numero > a.numero ? -1 : 0
        ),
      });
    }
    // setEsObj({ ...esObj, discursos: [esObj.discursos] });
  }
  function Ordenar(data) {
    if (!data) return;
    return data.sort((a, b) =>
      a.numero > b.numero ? 1 : b.numero > a.numero ? -1 : 0
    );
  }
  function OrderDisc(toOrder) {
    const tmp = Ordenar(toOrder.es?.discursos);
    if (!tmp) return;
    let count = 0;
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].status) {
        tmp[i].numToShow = count;
        count++;
      }
    }
    setEsObj({ ...data?.es, discursos: tmp });
    // console.log("Guardando");
  }

  if (esObj) {
    return (
      <div className={css.list}>
        <div className={css.info}>
          <div className={css.check}>
            <p className={css.checkbox}>Visible</p>
            <input
              type="checkbox"
              checked={esObj?.status}
              onChange={(e) => {
                setEsObj({
                  ...esObj,
                  status: e.target.checked,
                });
              }}
            />
          </div>
          <InputText
            title="Texto especial"
            placeholder="Ej: Asamblea, no hay reunión, etc"
            OnChangeText={(text) => {
              setEsObj({
                ...esObj,
                especial: text,
              });
            }}
            initialText={esObj?.especial}
          />
          <InputText
            title="Lectura"
            placeholder=""
            OnChangeText={(text) => {
              setEsObj({
                ...esObj,
                lectura: text,
              });
            }}
            initialText={esObj?.lectura}
          />
        </div>
        <div className={css.canciones}>
          <Cancion
            data={esObj?.canticos}
            index={"0"}
            editable={true}
            getData={(text) => {
              setEsObj({
                ...esObj,
                canticos: [text, esObj.canticos[1], esObj.canticos[2]],
              });
            }}
          />
          <Cancion
            data={esObj?.canticos}
            index={"1"}
            editable={true}
            getData={(text) => {
              setEsObj({
                ...esObj,
                canticos: [esObj.canticos[0], text, esObj.canticos[2]],
              });
            }}
          />
          <Cancion
            data={esObj?.canticos}
            index={"2"}
            editable={true}
            getData={(text) => {
              setEsObj({
                ...esObj,
                canticos: [esObj.canticos[0], esObj.canticos[1], text],
              });
            }}
          />
          <div>
            <Selector
              optionsList={["1", "2"]}
              title="Aseo"
              selectedValue={esObj?.aseo}
              returnValue={(value) => {
                setEsObj({
                  ...esObj,
                  aseo: value,
                });
              }}
            />
          </div>
        </div>
        {/* Privilegios */}
        <div className={css.privilegios}>
          <div className={css.box}>
            <Privilegio
              img={"acomodacion"}
              text={"Acomodación"}
              data={esObj?.acomodacion}
            />
            <Selector
              returnValue={(val) => {
                setEsObj({
                  ...esObj,
                  acomodacion: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={esObj?.acomodacion?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio
              data={esObj?.proyeccion}
              img={"proyeccion"}
              text={"Proyección"}
            />
            <Selector
              returnValue={(val) => {
                setEsObj({
                  ...esObj,
                  proyeccion: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={esObj?.proyeccion?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio data={esObj?.apoyo} img={"apoyo"} text={"Apoyo"} />
            <Selector
              returnValue={(val) => {
                setEsObj({
                  ...esObj,
                  apoyo: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={esObj?.apoyo?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio data={esObj?.camara} img={"camara"} text={"Cámara"} />
            <Selector
              returnValue={(val) => {
                setEsObj({
                  ...esObj,
                  camara: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={esObj?.camara?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio
              data={esObj?.traslado}
              img={"transporte"}
              text={"Transporte"}
            />
            <Selector
              returnValue={(val) => {
                setEsObj({
                  ...esObj,
                  traslado: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={esObj?.traslado?.name}
            />
          </div>
        </div>
        {/* Discursos */}
        <div className={css.discursos}>
          <div className={css.group}>
            <Discurso
              data={esObj?.discursos[0]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[1]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[2]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[3]}
              semana={data?.semana}
              isEditable={true}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />

            <Discurso
              data={esObj?.discursos[4]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[5]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
          </div>
          <div className={css.group}>
            <Discurso
              data={esObj?.discursos[6]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[7]}
              semana={data?.semana}
              isEditable={true}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[8]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[9]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[10]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
            <Discurso
              data={esObj?.discursos[11]}
              isEditable={true}
              semana={data?.semana}
              formPersonas={formdata.personas}
              getData={(data) => {
                if (data) {
                  SaveDisc(data);
                }
              }}
            />
          </div>
        </div>
        <Oracion
          isEditable={true}
          data={esObj?.oracion}
          formPersonas={formdata.personas}
          getData={(data) => {
            if (data) {
              setEsObj({
                ...esObj,
                oracion: GetPersonData(data.name),
              });
            }
          }}
        />
        <SingleBtn
          isSave={true}
          text={"Obtener info (Beta)"}
          isVisible={true}
          onClick={() => {
            // console.log("Obteniendo datos");
            GetScrapperURL((scrapper) => {
              fetch(`${scrapper?.url}?week=${data?.semana}&year=${data?.año}`, {
                headers: {
                  origin: "https://saapp-b154e.web.app/",
                },
              })
                .then(async (response) => {
                  const result = await response.json();
                  // console.log(result);
                  setEsObj({ ...result?.es, status: true });
                })
                .finally(() => {});
            });
          }}
        />
        <p className={css.title}>Fin de semana</p>
        <div className={css.privilegios}>
          <div className={css.check}>
            <p className={css.checkbox}>Visible</p>
            <input
              type="checkbox"
              checked={fdsObj?.status}
              onChange={(e) => {
                setFdsObj({
                  ...fdsObj,
                  status: e.target.checked,
                });
              }}
            />
          </div>
          <InputText
            title="Texto especial"
            placeholder="Ej: Asamblea, no hay reunión, etc"
            OnChangeText={(text) => {
              setFdsObj({
                ...fdsObj,
                especial: text,
              });
            }}
            initialText={fdsObj?.especial}
          />
          <Selector
            optionsList={["1", "2"]}
            title="Aseo"
            selectedValue={fdsObj?.aseo}
            returnValue={(value) => {
              setFdsObj({
                ...fdsObj,
                aseo: value,
              });
            }}
          />
          <div className={css.box}>
            <Privilegio
              img={"presidencia"}
              text={"Presidencia"}
              data={fdsObj?.presidencia}
            />
            <Selector
              returnValue={(val) => {
                setFdsObj({
                  ...fdsObj,
                  presidencia: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={fdsObj?.presidencia?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio
              img={"acomodacion"}
              text={"Acomodación"}
              data={fdsObj?.acomodacion}
            />
            <Selector
              returnValue={(val) => {
                setFdsObj({
                  ...fdsObj,
                  acomodacion: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={fdsObj?.acomodacion?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio
              data={fdsObj?.proyeccion}
              img={"proyeccion"}
              text={"Proyección"}
            />
            <Selector
              returnValue={(val) => {
                setFdsObj({
                  ...fdsObj,
                  proyeccion: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={fdsObj?.proyeccion?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio data={fdsObj?.apoyo} img={"apoyo"} text={"Apoyo"} />
            <Selector
              returnValue={(val) => {
                setFdsObj({
                  ...fdsObj,
                  apoyo: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={fdsObj?.apoyo?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio data={fdsObj?.camara} img={"camara"} text={"Cámara"} />
            <Selector
              returnValue={(val) => {
                setFdsObj({
                  ...fdsObj,
                  camara: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={fdsObj?.camara?.name}
            />
          </div>
          <div className={css.box}>
            <Privilegio
              data={fdsObj?.traslado}
              img={"transporte"}
              text={"Transporte"}
            />
            <Selector
              returnValue={(val) => {
                setFdsObj({
                  ...fdsObj,
                  traslado: GetPersonData(val),
                });
              }}
              optionsObj={formdata?.personas}
              selectedValue={fdsObj?.traslado?.name}
            />
          </div>
          <InputText
            title="Tema discurso"
            placeholder=""
            OnChangeText={(text) => {
              setFdsObj({
                ...fdsObj,
                discurso: {
                  tema: text,
                  img: fdsObj?.discurso?.img,
                },
              });
            }}
            initialText={fdsObj?.discurso?.tema}
          />
          <div className={css.box}>
            <Privilegio
              data={fdsObj?.discurso}
              img={"user"}
              text={"Discursante o imagen especial"}
            />
            <FileUploader
              returnURL={(url) => {
                setFdsObj({
                  ...fdsObj,
                  discurso: {
                    tema: fdsObj.discurso.tema,
                    img: url,
                  },
                });
              }}
              currentImg={data?.img}
              fileType={"image/png, image/jpeg"}
              archivo={"discursante.webp"}
              resolutionX={1500}
              resolutionY={1500}
              ruta={`vmc/${data?.semana}`}
            />
            <img
              src="/imgs/borrar.png"
              className={css.delete}
              onClick={() => {
                setFdsObj({
                  ...fdsObj,
                  discurso: {
                    tema: fdsObj.discurso.tema,
                    img: "",
                  },
                });
              }}
            />
            <p className={css.text}>
              Si tiene texto especial, puedes subir una imagen de fondo aqui
            </p>
          </div>
        </div>
        <div className={css.btns}>
          <SingleBtn
            isSave={false}
            text={"Eliminar Semana"}
            isVisible={data?.key ? true : false}
            onClick={() => {
              if (onDelete) {
                onDelete({ ...data, es: esObj, fds: fdsObj });
              }
            }}
          />
          <SingleBtn
            isSave={false}
            text={"Descartar cambios"}
            isVisible={canSave}
            onClick={() => {
              LoadData();
            }}
          />
          <SingleBtn
            isSave={true}
            text={"Guardar cambios"}
            isVisible={canSave}
            onClick={() => {
              onSave({ ...data, es: esObj, fds: fdsObj });
            }}
          />
        </div>
      </div>
    );
  } else {
    return <div>Sin datos</div>;
  }
};

export default VMCList;
