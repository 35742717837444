import React, { useEffect, useState } from "react";
import css from "../VMCRange/VMCRange.module.css";
import VMCMain from "../VMCMain/VMCMain";
import SelectorSemana from "../../VMCEditor/SelectorSemana/SelectorSemana";
import SingleBtn from "../../SingleBtn/SingleBtn";
const VMCRange = () => {
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(WeekNumber(new Date()));
  const [update, setUpdate] = useState(false);

  function WeekNumber(date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNumber;
  }
  return (
    <div className={css.main}>
      <div className={css.dates}>
        <SelectorSemana
          GetWeek={(week, lunes) => {
            setFrom(lunes.getTime());
          }}
        />
        <SelectorSemana
          GetWeek={(week, lunes) => {
            setTo(lunes.getTime());
          }}
        />
        <SingleBtn
          isSave={true}
          text={"Actualizar"}
          onClick={() => {
            setUpdate(!update);
          }}
        />
      </div>
      <VMCMain from={from} to={to} update={update} />
    </div>
  );
};

export default VMCRange;
