import React from "react";
import css from "../VMCMain/VMCMain.module.css";
import Semana from "../Semana/Semana";
import { useEffect, useState } from "react";
import { GetVMC, GetVMCFiltered } from "../../../Services/Firestore/Helper";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Spinner from "../../Spinner/Spinner";

const VMCMain = ({ from, to, update = false }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [content, setContent] = useState([]);
  const [tmp, setTmp] = useState([]);

  const nav = useNavigate();
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      nav("/mapas");
    },
    onSwipedRight: (eventData) => {
      nav("/carrito");
    },
    delta:70,
  });

  useEffect(() => {
    if (to) {
      GetVMC(setIsLoaded, setContent);
    } else {
      GetVMCFiltered(setIsLoaded, setContent, new Date());
    }
  }, [update]);
  useEffect(() => {
    Filter();
    // console.log(content);
  }, [content]);
  useEffect(() => {
    Filter();
  }, [from, to]);
  function Filter() {
    if (content) {
      const localTmp = [...content];
      if (to) {
        setTmp(
          localTmp
            .filter((item) => item?.date >= from && item?.date <= to)
            .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
        );
      } else {
        setTmp(localTmp);
      }
    }
  }
  function WeekNumber(date) {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
    return weekNumber;
  }

  if (!isLoaded) {
    return (
      <div className={css.main} {...handlers}>
        <Spinner />
      </div>
    );
  } else {
    if (tmp.length == 0) {
      return (
        <div className={css.main} {...handlers}>
          <p className={css.title}>Sin datos</p>
        </div>
      );
    } else {
      return (
        <div className={css.main} {...handlers}>
          <p className={css.title}>Programa de reuniones</p>
          <Semana data={tmp} filtered={to ? false : true} />
        </div>
      );
    }
  }
};

export default VMCMain;
