import React, { useEffect, useState } from "react";
import css from "../PrediList/PrediList.module.css";
import SingleBtn from "../../SingleBtn/SingleBtn";

const PrediList = ({ data, onEdit, onDelete }) => {
  const [toMap, setToMap] = useState([]);
  useEffect(() => {
    if (data) {
      setToMap(
        data.sort((a, b) =>
          a.horario > b.horario ? 1 : b.horario > a.horario ? -1 : 0
        )
      );
    }
  }, [data]);
  function Editar(obj) {
    if (!onEdit) return;
    onEdit(obj);
  }
  function Eliminar(obj) {
    if (!onDelete) return;
    onDelete(obj);
  }
  function RenderData() {
    return toMap.map((predi) => {
      return (
        <div className={css.box} key={predi.key}>
          <div className={css.info}>
            <p className={css.text}>Horario: {predi?.horario}</p>
            <p className={css.text}>Tipo: {predi?.tipo}</p>
            <p className={css.text}>Lugar: {predi?.lugar}</p>
            <p className={css.text}>Encargado: {predi?.encargado}</p>
            <p className={css.text}>Detalle: {predi?.detalle}</p>
            <div
              className={css.row}
              style={{ display: predi?.territorio?.length > 0 ? "" : "none" }}
            >
              <p className={css.datoText}>Datos:</p>
              {predi?.territorio?.map((item) => {
                return (
                  <p className={css.datoText} key={item}>
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
          <div className={css.editar}>
            <SingleBtn
              isSave={true}
              onClick={() => {
                Editar(predi);
              }}
              text="Editar"
            />
            <SingleBtn
              isSave={false}
              onClick={() => {
                Eliminar(predi);
              }}
              text="Eliminar"
            />
          </div>
        </div>
      );
    });
  }
  if (toMap.length > 0) {
    return RenderData();
  } else {
    return (
      <div>
        <p>Sin datos</p>
      </div>
    );
  }
};

export default PrediList;
