import React from "react";
import css from "../SingleBtn/SingleBtn.module.css";

const SingleBtn = ({ onClick, text, isSave, isVisible = true }) => {
  if (isVisible) {
    return (
      <div className={css.container}>
        <p
          className={`${css.btn} ${isSave ? css.save : css.delete}`}
          onClick={() => {
            if (!onClick) return;
            onClick();
          }}
        >
          {text}
        </p>
      </div>
    );
  }
};

export default SingleBtn;
