import React from "react";
import ItemCarrito from "../ItemCarrito/ItemCarrito";
import css from "../CarritoContainer/CarritoContainer.module.css";

const CarritoContainer = ({ data }) => {
  if (data) {
    return (
      <div className={css.main}>
        <h2 className={css.title}>
          {new Date(data[0].date).toLocaleDateString("es-CL", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
        </h2>
        {data?.map((item) => {
          return <ItemCarrito data={item} key={Math.random()} />;
        })}
      </div>
    );
  }
};

export default CarritoContainer;
