import React, { useState, useEffect } from "react";
import css from "../PrediSemanal/PrediSemanal.module.css";
import PrediDiaria from "../PrediDiaria/PrediDiaria";

const PrediSemanal = ({ data }) => {
  const [daily, setDaily] = useState([]);
  useEffect(() => {
    Ordenar();
  }, []);
  function GroupByDay(list) {
    const groups = list.reduce(
      (groups, item) => ({
        ...groups,
        [new Date(item.date).getDate()]: [
          ...(groups[new Date(item.date).getDate()] || []),
          item,
        ],
      }),
      {}
    );
    return groups;
  }
  function Ordenar() {
    const tmp = data;
    // console.log(data);
    const result = GroupByDay(tmp);
    const days = [];
    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        days.push(result[key]);
      }
    }
    const sorted = [];
    // sorted.push(
    // days.sort((a, b) =>
    //   new Date(a[0].año, a[0].mes - 1, a[0].dia).getTime() >
    //   new Date(b[0].año, b[0].mes - 1, b[0].dia).getTime()
    //     ? 1
    //     : new Date(b[0].año, b[0].mes - 1, b[0].dia).getTime() >
    //       new Date(a[0].año, a[0].mes - 1, a[0].dia).getTime()
    //     ? -1
    //     : 0
    // )
    const final = days.sort((a, b) =>
      a[0].date > b[0].date ? 1 : b[0].date > a[0].date ? -1 : 0
    );
    // );
    // console.log(final);
    // console.log(days);
    setDaily(final);
  }
  if (daily.length > 0) {
    return (
      <div className={css.main}>
        {daily.map((day) => {
          return (
            <PrediDiaria
              key={Math.random()}
              data={day}
              fecha={new Date(day[0].date).toLocaleDateString("es-CL", {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
            />
          );
        })}
      </div>
    );
  }
};

export default PrediSemanal;
