import React from "react";
import css from "../Privilegio/Privilegio.module.css";

const Privilegio = ({ data, text, img }) => {
  return (
    <div className={css.main}>
      <div className={css.container}>
        <img
          src={data?.img != "" ? data?.img : "../imgs/user.png"}
          className={css.img}
        />
        <img
          style={{ display: img ? "" : "none" }}
          src={img ? `../imgs/${img}.png` : ""}
          className={css.icon}
        />
      </div>
      <p className={css.text} style={{ display: text ? "" : "none" }}>
        {text}
      </p>
    </div>
  );
};

export default Privilegio;
