import React, { useEffect } from "react";
import css from "../DiaSemana/DiaSemana.module.css";
import Privilegio from "../Privilegio/Privilegio";
import Cancion from "../Cancion/Cancion";
import Seccion from "../Seccion/Seccion";
import Discurso from "../Discurso/Discurso";
import Oracion from "../Oracion/Oracion";
import { useState } from "react";

const DiaSemana = ({ data, date }) => {
  const [content, setContent] = useState({
    ...data,
    discursos: Ordenar(data?.discursos),
  });
  const [lunes, setLunes] = useState(new Date());
  const [domingo, setDomingo] = useState(new Date());
  const [martes, setMartes] = useState(new Date());
  useEffect(() => {
    if (content) {
      CalculateWeek();
    }
  }, [content]);

  function CalculateWeek() {
    let primerDia = new Date(date); // Primer día de la semana
    let lunes = new Date(primerDia);
    let martes = new Date(primerDia);
    let domingo = new Date(primerDia);
    lunes.setDate(primerDia.getDate()); // Sumar 2 días para llegar al martes
    domingo.setDate(primerDia.getDate() + 6); // Sumar 2 días para llegar al martes
    martes.setDate(primerDia.getDate() + 3); // Sumar 2 días para llegar al martes
    martes.setHours(0, 0, 0, 0);
    // console.log(martes.toLocaleDateString() + " " + martes.toTimeString());
    setLunes(lunes);
    setMartes(martes);
    setDomingo(domingo);
  }

  function Ordenar(data) {
    return data.sort((a, b) =>
      a.numero > b.numero ? 1 : b.numero > a.numero ? -1 : 0
    );
  }

  if (content) {
    if (content?.status) {
      if (content?.especial != "" && content?.lectura == "") {
        return (
          <div className={css.main}>
            <div className={css.header}>
              <p
                className={css.title}
              >{`${lunes.getDate()} ${lunes.toLocaleString("es-CL", {
                month: "long",
              })} al ${domingo.getDate()} ${domingo.toLocaleString("es-CL", {
                month: "long",
              })}`}</p>
            </div>
            <p className={css.text}>
              {content?.especial ? content?.especial : ""}
            </p>
          </div>
        );
      } else {
        return (
          <div className={css.main}>
            <div className={css.header}>
              <p
                className={css.title}
              >{`${lunes.getDate()} ${lunes.toLocaleString("es-CL", {
                month: "long",
              })} al ${domingo.getDate()} ${domingo.toLocaleString("es-CL", {
                month: "long",
              })} ${content?.lectura ? " | " + content?.lectura : ""}`}</p>
              <p className={css.aseo}>{`Grupo ${
                content?.aseo ? content?.aseo : ""
              }`}</p>
            </div>
            <p
              className={css.text}
              style={{ display: content?.especial ? "" : "none" }}
            >
              {content?.especial ? content?.especial : ""}
            </p>
            <div className={css.topBox}>
              <Privilegio
                data={content?.proyeccion}
                text={"Proyección"}
                img={"proyeccion"}
              />
              <Privilegio data={content?.apoyo} text={"Apoyo"} img={"apoyo"} />
              <Privilegio
                data={content?.acomodacion}
                text={"Acomodación"}
                img={"acomodacion"}
              />
              <Privilegio
                data={content?.camara}
                text={"Cámara"}
                img={"camara"}
              />
              <Privilegio
                data={content?.traslado}
                text={"Traslado"}
                img={"transporte"}
              />
            </div>
            <div className={css.list}>
              <div className={css.left}>
                <Cancion data={content?.canticos} index={"0"} />
                <Seccion nombre="tesoros" />
                <Discurso data={content?.discursos[0]} />
                <Discurso data={content?.discursos[1]} />
                <Discurso data={content?.discursos[2]} />
                <Discurso data={content?.discursos[3]} />
                <Seccion nombre="maestros" />
                <Discurso data={content?.discursos[4]} />
              </div>
              <div className={css.right}>
                <Discurso data={content?.discursos[5]} />
                <Discurso data={content?.discursos[6]} />
                <Discurso data={content?.discursos[7]} />
                <Cancion data={content?.canticos} index={"1"} />
                <Seccion nombre="vida" />
                <Discurso data={content?.discursos[8]} />
                <Discurso data={content?.discursos[9]} />
                <Discurso data={content?.discursos[10]} />
                <Discurso data={content?.discursos[11]} />
                <div className={css.row}>
                  <Cancion data={content?.canticos} index={"2"} />
                  <Oracion data={content?.oracion} />
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
};

export default DiaSemana;
